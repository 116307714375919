import React from 'react'

// mui components
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import EmailField from '../../components/EmailField'
import PasswordField from '../../components/PasswordField'
import UsernameField from '../../components/UsernameField'
import ConfirmPasswordField from '../../components/ConfirmPasswordField'

import api from '../../api-client'
import { notification } from '../../parts/notify'
import { focusField } from '../../global'

const SignupForm = (props) => {
  // -- STATES --
  const [showPassword, setShowPassword] = React.useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [passwordConfirm, setPasswordConfirm] = React.useState('')
  const [rememberMe, setRememberMe] = React.useState(false)

  // -- PROPS --
  const {isLoading, setIsLoading} = props

  // -- FUNCTIONS --
  const signUp = () => {
    // checks
    if (!email) {
      notification('No Email!', '', 'warning')
      focusField('email')
      return
    }
    if (!username) {
      notification('No Username!', '', 'warning')
      focusField('username')
      return
    }
    if (!password) {
      notification('No Password!', '', 'warning')
      focusField('password')
      return
    }
    if (!passwordConfirm) {
      notification('No Password Confirmation!', '', 'warning')
      focusField('password_confirm')
      return
    }
    if (password.length < 6) {
      notification('Password is too short!', '', 'warning')
      focusField('password')
      return
    }
    if (password.length > 128) {
      notification('Password is too long!', '', 'warning')
      focusField('password')
      return
    }
    if (password != passwordConfirm) {
      notification('Passwords do not match!', '', 'warning')
      focusField('password_confirm')
      return
    }
    setIsLoading(true)
    // makes sure a user with the email does not already exist, then creates the user
    let day = new Date().getDate()
    let month = new Date().getMonth() + 1
    let year = new Date().getFullYear()
    let time = new Date().toLocaleTimeString()
    api.post('/api/create_user', {
      email: email, 
      username: username, 
      password: password,
      day: day,
      month: month,
      year: year,
      time: time
    }).then(({ data }) => {
      if (data == 'success') {
        // signs in the user that was just created once the request returns successful
        api.post('/users/sign_in', {
          user: {
            email: email, 
            password: password,
            remember_me: rememberMe ? 1 : 0
          }
        }).then(() => {
          window.location.replace(window.location.origin)
        }, () => {
          notification('500 Internal Server Error', 'users/sign_in', 'error')
          setIsLoading(false)
        })
      }
      else {
        notification('ERROR', data, 'error')
        setIsLoading(false)
        focusField('email')
      }
    }, () => {
      notification('500 Internal Server Error', 'api/create_user', 'error')
      setIsLoading(false)
    })
  }

  // -- RETURN STATEMENT --
  return (
    <Grid container spacing={1} direction={'column'} >
      <Grid item >
        <EmailField
          value={email}
          setValue={setEmail}
          autoFocus
          fullWidth
          disabled={isLoading}
          onKeyUp={(e) => { if (e.key == 'Enter') { signUp() }}}
        />
      </Grid>
      <Grid item >
        <UsernameField
          value={username}
          setValue={setUsername}
          fullWidth
          disabled={isLoading}
          onKeyUp={(e) => { if (e.key == 'Enter') { signUp() }}}
        />
      </Grid>
      <Grid item >
        <PasswordField
          value={password}
          setValue={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          fullWidth
          disabled={isLoading}
          onKeyUp={(e) => { if (e.key == 'Enter') { signUp() }}}
        />
      </Grid>
      <Grid item >
        <ConfirmPasswordField
          value={passwordConfirm}
          setValue={setPasswordConfirm}
          showPassword={showPasswordConfirm}
          setShowPassword={setShowPasswordConfirm}
          fullWidth
          disabled={isLoading}
          onKeyUp={(e) => { if (e.key == 'Enter') { signUp() }}}
        />
      </Grid>
      <Grid item >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={() => { setRememberMe(!rememberMe) }}
              />
            }
            label={'Remember Me'}
          />
        </FormGroup>
      </Grid>
      <Grid item >
        <LoadingButton
          fullWidth
          loading={isLoading}
          onClick={signUp}
          variant={'contained'}
        >
          Sign Up
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default SignupForm