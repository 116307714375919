import React from 'react'

// mui components
import Card from '@mui/material/Card'

import EasyTabs from '../components/EasyTabs'
import LoginForm from './Login/LoginForm'
import SignupForm from './Login/SignupForm'

// puts login / sign up card right in the center of the screen
const styles = {
  card: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '1px'
  }
}

const Login = () => {
  // -- STATES --
  const [tabValue, setTabValue] = React.useState('login')
  const [isLoading, setIsLoading] = React.useState(false)

  // -- VARIABLES --
  const tabs = [
    {
      label: 'Log In',
      value: 'login',
      content: (<LoginForm isLoading={isLoading} setIsLoading={setIsLoading} />)
    },
    {
      label: 'Sign Up',
      value: 'signup',
      content: (<SignupForm isLoading={isLoading} setIsLoading={setIsLoading} />)
    }
  ]

  // -- RETURN STATEMENT --
  return (
    <>
      <Card sx={styles.card} >
        <EasyTabs
          currentTab={tabValue}
          setCurrentTab={setTabValue}
          tabs={tabs}
          disabled={isLoading}
        />
      </Card>
    </>
  )
}

export default Login