import React from 'react'

// mui components
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import EmailField from '../../components/EmailField'
import PasswordField from '../../components/PasswordField'

import api from '../../api-client'
import { notification } from '../../parts/notify'
import { focusField } from '../../global'

const LoginForm = (props) => {
  // -- STATES --
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [showPassword, setShowPassword] = React.useState(false)
  const [rememberMe, setRememberMe] = React.useState(false)

  // -- PROPS --
  const {isLoading, setIsLoading} = props

  // -- FUNCTIONS --
  const logIn = () => {
    setIsLoading(true)
    // runs checks to see if the email / password is valid, account is disabled / deleted, etc
    api.post('/api/submit_login', {email: email, password: password}).then(({ data }) => {
      if (data == 'success') {
        // runs actual sign in method
        api.post('/users/sign_in', {
          user: {
            email: email, 
            password: password, 
            remember_me: rememberMe ? 1 : 0
          }
        }).then(() => {
          window.location.replace(window.location.origin)
        }, () => {
          notification('500 Internal Server Error', 'users/sign_in', 'error')
          setIsLoading(false)
        })
      }
      else {
        notification('ERROR', data, 'error')
        setIsLoading(false)
        focusField('email')
      }
    }, () => {
      notification('500 Internal Server Error', 'api/submit_login', 'error')
      setIsLoading(false)
    })
  }

  // -- RETURN STATEMENT --
  return (
    <Grid container spacing={1} direction={'column'} >
      <Grid item >
        <EmailField
          value={email}
          setValue={setEmail}
          autoFocus
          fullWidth
          onKeyUp={(e) => { if (e.key == 'Enter') { logIn() }}}
        />
      </Grid>
      <Grid item >
        <PasswordField
          value={password}
          setValue={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          fullWidth
          disabled={isLoading}
          onKeyUp={(e) => { if (e.key == 'Enter') { logIn() }}}
        />
      </Grid>
      <Grid item >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={() => { setRememberMe(!rememberMe) }}
              />
            }
            label={'Remember Me'}
          />
        </FormGroup>
      </Grid>
      <Grid item >
        <LoadingButton
          fullWidth
          loading={isLoading}
          variant={'contained'}
          onClick={logIn}
          disabled={isLoading}
        >
          Log In
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default LoginForm